
import { defineComponent, onMounted } from "vue";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
// import { MenuComponent } from "@/assets/ts/components/MenuComponent";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
    batchdata:Array
  },
  components: {
    // Dropdown1,
  },
  data() {
    return {
      entityTypes: [],
      trainee: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      formData: {
        entity_type_id: 1016,
      },
      lists: [],

      tableData: [
        {
          id: 1,
          name: "MOHAMMAD SAZZAD HOSEN",
          organization: "No organization",
          ref_no: "1234",
          reg_no: "1234",
        },
        {
          id: 2,
          name: "MD. MARUF AHMED",
          organization: "No organization",
          ref_no: "12345",
          reg_no: "12345",
        },
      ],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Industry Association/Organization",
          key: "organization",
          sortable: true,
        },
        {
          name: "Total Target (As Per Contract)",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Enrollment",
          key: "reg_no",
          sortable: false,
        },
        {
          name: "Trainee Name",
          key: "name",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
    await this.filterEntity();
  },
  methods: {
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async filterEntity() {
      await ApiService.get(
        "entity/infos?entity_type=" + this.formData.entity_type_id
      )
        .then((response) => {
          this.lists = response.data;
          console.log(response);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
  setup() {
    const list = [
      {
        orderid: "56037-XDER",
        country: {
          name: "Brasil",
          code: "PH",
        },
        date: {
          value: "05/28/2020",
          remarks: "Paid",
        },
        progress: {
          value: "50",
          color: "primary",
        },
        company: {
          name: "Intertico",
          fields: "Web, UI/UX Design",
        },
        status: {
          label: "Approved",
          color: "success",
        },
        total: "$3560",
      },
      {
        orderid: "05822-FXSP",
        country: {
          name: "Belarus",
          code: "BY",
        },
        date: {
          value: "04/18/2021",
          remarks: "Paid",
        },
        progress: {
          value: "50",
          color: "primary",
        },
        company: {
          name: "Agoda",
          fields: "Houses & Hotels",
        },
        status: {
          label: "In Progress",
          color: "warning",
        },
        total: "$4850",
      },
      {
        orderid: "4472-QREX",
        country: {
          name: "Phillipines",
          code: "BH",
        },
        date: {
          value: "07/23/2019",
          remarks: "Paid",
        },
        progress: {
          value: "50",
          color: "primary",
        },
        company: {
          name: "RoadGee",
          fields: "Transportation",
        },
        status: {
          label: "Success",
          color: "danger",
        },
        total: "$8376",
      },
      {
        orderid: "00347-BCLQ",
        country: {
          name: "Argentina",
          code: "BR",
        },
        date: {
          value: "12/21/2021",
          remarks: "Paid",
        },
        progress: {
          value: "50",
          color: "primary",
        },
        company: {
          name: "The Hill",
          fields: "Insurance",
        },
        status: {
          label: "Rejected",
          color: "info",
        },
        total: "$9486",
      },
      {
        orderid: "59486-XDER",
        country: {
          name: "Agoda",
          code: "BT",
        },
        date: {
          value: "05/28/2020",
          remarks: "Paid",
        },
        progress: {
          value: "50",
          color: "primary",
        },
        company: {
          name: "Phillipines",
          fields: "Transportation",
        },
        status: {
          label: "Approved",
          color: "primary",
        },
        total: "$8476",
      },
    ];

    return {
      list,
    };
  },
});
